import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  title = 'wedding';
  selectedPhotos: { file: File; url: string; name: string }[] = [];

  constructor(private storage: AngularFireStorage, private router: Router) {}
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  previewPhotos(event: any) {
    const files = event.target.files;
    this.selectedPhotos = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.selectedPhotos.push({
            file: file,
            url: e.target.result,
            name: file.name,
          });
        };

        reader.readAsDataURL(file);
      }
    }
  }

  confirmUpload() {
    if (window.confirm('Do you really want to upload these photos?')) {
      for (let i = 0; i < this.selectedPhotos.length; i++) {
        const photo = this.selectedPhotos[i];
        const filePath = `wedding-photos/${photo.name}`;
        const fileRef = this.storage.ref(filePath);
        const task = this.storage.upload(filePath, photo.file);

        task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              fileRef.getDownloadURL().subscribe((url) => {
                console.log(`File available at: ${url}`);
                // You can display the uploaded file URL or save it as needed
              });
            })
          )
          .subscribe();
      }
      // Clear the selected photos after upload
      this.selectedPhotos = [];
    }
  }

  goToUploadedPhotos() {
    this.router.navigate(['/uploaded-photos']);
  }
}
