// environment.ts
export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBFtg3lh-EfLqYnRzrlylvP08MSLLU46-s',
    authDomain: 'wedding-2cf8c.firebaseapp.com',
    projectId: 'wedding-2cf8c',
    storageBucket: 'wedding-2cf8c.appspot.com',
    messagingSenderId: '635771858943',
    appId: '1:635771858943:web:6c2f46619cf1f7516541ca',
  },
};
