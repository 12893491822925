import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-uploaded-photos',
  templateUrl: './uploaded-photos.component.html',
  styleUrls: ['./uploaded-photos.component.scss'],
})
export class UploadedPhotosComponent implements OnInit {
  photos: { url: string; name: string }[] = [];

  constructor(private storage: AngularFireStorage, private router: Router) {}

  ngOnInit(): void {
    this.loadUploadedPhotos();
  }

  loadUploadedPhotos() {
    const photosRef = this.storage.ref('wedding-photos');
    photosRef.listAll().subscribe((result) => {
      result.items.forEach((itemRef) => {
        itemRef.getDownloadURL().then((url) => {
          this.photos.push({ url: url, name: itemRef.name });
        });
      });
    });
  }

  goBack() {
    this.router.navigate(['/']);
  }
}
