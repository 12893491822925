import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { UploadedPhotosComponent } from './uploaded-photos/uploaded-photos.component';
import { AppRoutingModule } from './app-routing.module';
import { HomePageComponent } from './home-page/home-page.component'; // Import AppRoutingModule

@NgModule({
  declarations: [AppComponent, UploadedPhotosComponent, HomePageComponent],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AppRoutingModule, // Use AppRoutingModule for routing
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
