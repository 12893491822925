<div class="uploaded-photos-container">
	<button (click)="goBack()"
		class="back-button">
		Back
	</button>
	<h1>Uploaded Photos</h1>
	<div *ngIf="photos.length > 0">
		<div *ngFor="let photo of photos"
			class="photo-container">
			<img [src]="photo.url"
				[alt]="photo.name">
		</div>
	</div>
	<div *ngIf="photos.length === 0">
		<p>
			No photos uploaded yet.
		</p>
	</div>
</div>
